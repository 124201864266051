import React from 'react';
import ReactTooltip from "react-tooltip";
import Fab from '@mui/material/Fab';

// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
// import Icon from '@material-ui/core/Icon';
import TelegramIcon from '@mui/icons-material/Telegram';

import RespUtils from '../util/RespUtils';

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGuide: false,
    }
  }

  render() {
    // const styles = theme => ({
    //   root: {
    //     width: '100%',
    //     marginTop: theme.spacing.unit * 3,
    //     overflowX: 'auto',
    //   },
    //   fab: {
    //       position: "fixed",
    //       bottom: theme.spacing.unit * 2,
    //       right: theme.spacing.unit * 3
    //   },
    //   extendedIcon: {
    //     marginRight: theme.spacing.unit,
    //   },
    // });
    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 45,
        left: 'auto',
        position: 'fixed',
    };
    const astyle = {
      "color": "inherit",
      "textDecoration": "inherit",
    }

    let size = RespUtils.isMobileView() ? "small" : "large";
    return (
      <div>
        <ReactTooltip id="help" place="top" type="dark" effect="float" multiline={true} />
        <Fab color="primary" aria-label="Telegram Support" style={style} size={size}>
          <span data-for="help" data-tip="Got questions? Talk to us on Telegram" data-iscapture="true">
            <a href="https://t.me/SmartStake" style={astyle} target="_blank">
              <TelegramIcon fontSize="large"/>
            </a>
          </span>
        </Fab>
      </div>
    );
  }
}

export default Contact;
