import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {Container, Row, Col} from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import constants from "../constants";
import "./Dashboards.css";
import LogoUtils from "../util/LogoUtils";
import Utilities from "../util/Utilities";
import commonConstants from "../commonConstants";

class Dashboards extends Component {
  constructor(props) {
    super(props);
    this.state = {
        visible: false,
        dashboards: [],
    };
    this.switchModal = this.switchModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  async componentDidMount() {
    // let url = "https://static.smartstake.io/resources/dashboards.json";
    let url = commonConstants.DASHBOARDS_URL;

    fetch(url, { referrerPolicy: "no-referrer" })
      .then(this.handleErrors)
      .then((res) => res.json())
      .then((data) => {
        let ssDashboards = data["ssDashboards"];

        if (ssDashboards && ssDashboards.length != 0) {
          ssDashboards = Utilities.addIndex(ssDashboards);
          this.setState({ dashboards: ssDashboards });
        }
      })
      .catch((error) => console.log(error));
  }

  switchModal() {
    // console.log("in switchModal");
    if (this.state.visible === false) {
    //   console.log("setting delegates to true");
      this.setState({visible: true});
    } else {
      this.setState({visible: false});
    }
  }

  hideModal() {
    // console.log("in hideModal");
    this.setState({visible: false});
  }

  //   {/* <button onClick={this.switchModal} className="animLink">{constants.app.title}</button> */}
  /*
            <NavDropdown menuvariant="dark" variant="dark" bg="customNavBar" onClick={this.switchModal} 
                title={<span className="customNavLink">{constants.app.title}</span>} id="nav-dropdown">
            </NavDropdown>
  */
  render() {
    return (
        <React.Fragment>
            {/* <button onClick={this.switchModal} className="animLinkMenu animLink">{LogoUtils.defaultChainLogo()} {constants.app.appName} */}
            <button onClick={this.switchModal} className="animLinkMenu animLink">{constants.app.appName}
                <KeyboardArrowDownIcon className="mInfoIcon" style={{fill: "white"}} fontSize="medium" />
            </button>
    
            {this.state.visible === true && 
            <Modal show={this.state.visible} onHide={this.hideModal} size="lg" centered className="ssModal">
                <Modal.Header closeButton>
                <Modal.Title>Smart Stake</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container className="containerLayout" fluid>
                    <Row className="statRowLayout">
                        {this.state.dashboards.map(dashboard => (
                            this.renderDashboardLink(dashboard)
                        ))}
                    </Row>
                    </Container>  
                </Modal.Body>
            </Modal>}
        </React.Fragment>
    );
  }

  renderDashboardLink(dashboard) {
    // console.log(dashboard);
    let styleName = dashboard.app === constants.app.APP_KEY ? "animLinkSelected animLink": "animLink";
    // console.log(styleName);
    let title = dashboard.title;
    if (!title) {
        title = dashboard.name;
    }
    return (<Col className="statColLayout" xs={6} sm={6} md={4} lg={4}>
            <div className="dashboard" key={dashboard.index}>
                <a className={styleName} href={dashboard.website}
                  >{dashboard.logoFileName && Dashboards.chainLogo(dashboard.logoFileName)} {title}</a>
            </div>
        </Col>);
  }

  static chainLogo(logo) {
    let size = LogoUtils.getImageSize(true);

    let imagePath = constants.SS_CHAIN_LOGO_BASE_URL + logo;
    //console.log(imagePath);
    return (<img className="logoStyle" width={size} height={size} src={imagePath}/>);
  }

}

export default Dashboards;
