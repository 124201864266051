import React from 'react';
import { Container, Row } from 'react-bootstrap';

// import Loading from '../components/Loading';
// import PageHeader from '../components/PageHeader';
import Chain from './Chain';
import "./Chains.css";

class Chains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chains: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    const token = Math.round(new Date().getTime() / 1000);
    let url = "https://prod.smartstakeapi.com/smartstake?key=2mwTEDr9zXJH323M&app=LUNA&token=" + token;

    fetch(url, { referrerPolicy: "no-referrer" })
      .then(this.handleErrors)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          this.setState({"chains": data, isLoading: false});
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    let chains = this.state.chains;
    if (this.props.sortBy === "apr") {
      chains.sort((a, b) => b.currentRewardRate - a.currentRewardRate);
    } else if (this.props.sortBy === "fdv") {
      chains.sort((a, b) => b.fdv - a.fdv);
    } else if (this.props.sortBy === "mcap") {
      chains.sort((a, b) => b.usdMcap - a.usdMcap);
    } else if (this.props.sortBy === "stake") {
      chains.sort((a, b) => b.tvl - a.tvl);
    } else if (this.props.sortBy === "vp") {
      chains.sort((a, b) => b.votingPowerPercentage - a.votingPowerPercentage);
    } else {
      // sort alphabetically by name
      chains.sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
      <div className="chains">
        <Container fluid>
          <Row>
            {this.state.chains.map(function (chain) {
              // console.log(chain);
              if (chain != "") {
                return (<Chain chain={chain} key={chain.app} />)
              }
            })}
          </Row>
        </Container>
      </div>
    );
  }

}

export default Chains;
