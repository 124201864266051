import React from 'react';

import SPUtilities from '../util/SPUtilities';
import RespUtils from '../util/RespUtils';
import KeybaseLogo from '../base/KeybaseLogo';
import constants from '../constants';
import commonConstants from '../commonConstants';

class LogoUtils extends React.Component {
  static getImageSize(large) {
    let size = 16;
    if (large && RespUtils.isMobileView()) {
      size = 32;
    } else if (large) {
      size = 32;
    } else if (RespUtils.isNotMobileView()) {
      size = 20;
    }

    return size;
  }

  static defaultLogoFormatter() {
    let size = LogoUtils.getImageSize(true);

    return (<img className="logoStyle" width={size} height={size} src={constants.app.defaultLogo} />);
  }

  static logoFormatter(address, website, large) {
    if (!address) return "";

    let size = LogoUtils.getImageSize(large);
    let imagePath = "";

    return (<a className="black-a" target="_blank"
      href={SPUtilities.getUrl(website)}><img onError={this.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={imagePath}/></a>);
  }

  static addDefaultSrc(ev){
    ev.target.src = constants.app.defaultLogo;
  }

  static formatLogo(cell, row) {
    return LogoUtils.logoFormatter(cell, row.website, false);
  }

  static formatLogoFlag(cell, row) {
    if (!cell) return "";

    if (row.hasLogo) {
      return LogoUtils.logoFormatter(cell, row.website, false);
    }


    let size = LogoUtils.getImageSize(false);
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={constants.app.defaultLogo}/>;
  }

  static formatLogoByIdentity(identity, row) {
    // console.log(identity);
    if (!identity) {
      return LogoUtils.defaultLogoFormatter();
    }

    return <KeybaseLogo identity={identity} />

  }

  static formatLogoByPath(logoPath, row) {
    //console.log(logoPath);
    if (!logoPath) {
      return LogoUtils.defaultLogoFormatter();
    }

    let size = LogoUtils.getImageSize(true);
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={logoPath} />;
  }

  static formatLogoByPathAndSize(logoPath, row) {
    //console.log(logoPath);
    if (!logoPath) {
      return (<img className="logoStyle" width="24" height="24" src={constants.app.defaultLogo} />);
    }

    let size = 24;
    return <img onError={LogoUtils.addDefaultSrc} className="logoStyle"
      width={size} height={size} src={logoPath} />;
  }

  static formatLogoByPathAndSizeAndStyle(logoPath, row, styleName) {
    //console.log(logoPath);
    if (!logoPath) {
      return (<img className={styleName} width="24" height="24" src={constants.app.defaultLogo} />);
    }

    let size = 24;
    return <img onError={LogoUtils.addDefaultSrc} className={styleName}
      width={size} height={size} src={logoPath} />;
  }

  static projectLogo(logo, row) {
    // console.log(logo);
    // console.log(row);
    return LogoUtils.projectLogoBySize(logo, row, true);
  }

  static getProjectLogoPath(logoPath) {
    let imagePath = constants.app.defaultProjectLogo;

    if (logoPath && logoPath !== "") {
      if (logoPath.toLowerCase().startsWith("http")) {
        imagePath = logoPath;
      } else {
        imagePath = constants.PROJECT_LOGO_BASE_URL + logoPath;
      }
    }

    return imagePath;
  }

  static projectLogoBySize(logo, row, largeSize) {
      // if (!logo) return "";
    let size = LogoUtils.getImageSize(largeSize);
    let imagePath = LogoUtils.getProjectLogoPath(row.logo);
    /*let imagePath = constants.app.defaultProjectLogo;

    if (row.logo && row.logo != "") {
      imagePath = constants.PROJECT_LOGO_BASE_URL + row.logo;
    }*/

    // console.log("project: ", row.name, ", logo: ", logo);

    // console.log(imagePath);
    // return (<object type="image/png" width={size} height={size} data={imagePath}>
    //   <img className="img-valign" width={size} height={size} src="images/logo/default.png" />
    // </object>);

    return (<img onError={this.addDefaultSrc} className="logoStyle" 
      width={size} height={size} src={imagePath}/>);
  }

  static chainLogo(logoFileName) {
      // if (!logo) return "";
    let size = LogoUtils.getImageSize(false);

    let imagePath = commonConstants.networkLogoBaseUrl + logoFileName;

    // let imagePath = "/images/chains/" + logoFileName;

    return (<img className="logoStyle" 
      width={size} height={size} src={imagePath}/>);
  }

  static defaultChainLogo() {
    let size = LogoUtils.getImageSize(true);

    let imagePath = constants.app.defaultProjectLogo;

    return (<img className="logoStyle" 
      width={size} height={size} src={imagePath}/>);
  }
}

export default LogoUtils;
