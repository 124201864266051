import React from "react";
// import ChartTitle from "../base/ChartTitle";
import Chains from "../chains/Chains";

import WhyChoose from "./WhyChoose";
import "./Home.css";
import RespUtils from "../util/RespUtils";
import PageHeader from "../components/PageHeader";
import Dropdown from "../base/Dropdown";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "default",
    };
    this.renderSortBy = this.renderSortBy.bind(this);
    this.changeSortBy = this.changeSortBy.bind(this);
  }

  render() {
    return <React.Fragment>
        {this.getHeader()}
        <PageHeader title={"Networks"} thisObj={this} additionalLink={this.renderSortBy} /> 
        <Chains sortBy={this.state.sortBy} />
        <br/>
        <p>** Expected returns is a just-in-time stat and does not consider future change in staking returns based on higher/lower total stake,
           or change in network parameters, or reflect token price changes over time. For all practical purposes, assume that the realized staking 
           returns over a long term will be lower than current numbers. It also does not consider validator commission.</p>
        <br/>
        <WhyChoose />
    </React.Fragment>;
  }

  getHeader() {
    return <React.Fragment>
        {!RespUtils.isMobileView() && (<h1 className="homeMain">Smart Stake, validating the cosmos since 2019</h1>)}
        {RespUtils.isMobileView() && (<h1 className="homeMain">Smart Stake</h1>)}
        {/* <div className="homeDetails">Transparent, professional, and feature rich Validator. </div> */}
        <div className="homeDetails"><h2 className="homeDetails">Providing validator services in 35+ networks and supported by 30k+ delegates.&nbsp;
        {/* </div><div className="homeDetails"> */}
        Bare metal servers, automated monitoring and alerting to ensure consistently high uptime.&nbsp;
        Your analytics hub for assessing Validators, Network, Relayers, & Decentralization metrics.&nbsp;
        Delegate to Smart Stake and help support new features.</h2></div>
        <p/>
        <br/>
    </React.Fragment>
  }

  renderSortBy() {
    // console.log("in render sort by");
    let sortBy = [{"code": "apr", "description": "APR (current)"}, 
      {"code": "fdv", "description": "FDV"}, 
      {"code": "mcap", "description": "Market Cap"}, 
      {"code": "name", "description": "Name"},
      {"code": "stake", "description": "Staked"},
      {"code": "vp", "description": "Voting Power"}, ];

    return <Dropdown onSelect={this.changeSortBy} values={sortBy} 
      customValue={"Sort By"} size="lg" className="sortOrder" />;
  }

  changeSortBy(e) {
    // console.log(e);
    // console.log(e.target.value);
    this.setState({"sortBy": e.target.value});
  }
}

export default Home;
