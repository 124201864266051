import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { Button, Container, Form } from 'react-bootstrap';

import './FeedbackForm.css'; // Ensure you add CSS for the modal
import PageHeader from './PageHeader';

const FeedbackForm = () => {
  // console.log("in feedback form");
  const [feedback, setFeedback] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedbackError, setFeedbackError] = useState(null);
  const [contactError, setContactError] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    // console.log("in submit");
    event.preventDefault();
    if (contactInfo.length < 5) {
        setContactError("Please enter email address or telegram or twitter handle.");
        return;
    } else if (feedback.length < 5) {
        setFeedbackError("Invalid feedback content. Please share your feedback in bit more details.");
        return;
    } else if (feedback.length > 2000 ) {
        setFeedbackError("Invalid feedback content. Please share your feedback in <2000 characters.");
        return;
    }

    setLoading(true);
    setSuccess(false);
    setFeedbackError(null);
    setContactError(null);
    setError(null);

    const data = {
      feedback,
      contactInfo,
    };

    try {
      const token = Math.round(new Date().getTime() / 1000);
      let url = "https://prod.smartstakeapi.com/feedback?key=2mwTEDr9zXJH323M&app=COM&token=" + token;
      // console.log(url)
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      // console.log(response);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSuccess(true);
      setFeedback('');
      setContactInfo('');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log("returning modal");
  return (
        <div>
            <PageHeader title={"Submit Your Enquiry/Feedback"} />
            <p>Please use the below form for:
                <ul>
                    <li>feedback related to validator on any of the supported networks</li>
                    <li>any questions/issues related to staking</li>
                    <li>establishing contact with the validator</li>
                    <li>new network enquiries</li>
                    <li>new analytics features or issues with any existing feature</li>
                </ul>
            </p>
            <p>Also, <a href="https://t.me/SmartStake" target="_blank" title="telegram" className="mediaCustom">
                    Smart Stake telegram <FontAwesomeIcon icon={faTelegram} /> group</a> is always available for interactive conversations with the validator and the Smart Stake Community.</p>
            <br/>
            <Container fluid className="containerLayout container-fluid chartContainer">
                <div className="chartGridContainer">
                    <div className="chartBg">
                        <PageHeader title={"Feedback Form"} />
                        <br/>
                        {success && <p className='success'>Enquiry/Feedback submitted successfully!</p>}
                        {error && <p style={{ color: 'red' }}>Error: {error}</p>} 
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email/Telegram Handle/Twitter Handle:</Form.Label>
                                <Form.Control placeholder="" onChange={e => setContactInfo(e.target.value)} value={contactInfo} />
                                {contactError && <Form.Text className='headerWarning'>{contactError}</Form.Text>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="feedback">
                                <Form.Label>Feedback/Question:</Form.Label>
                                <Form.Control as="textarea" placeholder="" rows="5" onChange={e => setFeedback(e.target.value)} value={feedback} />
                                {feedbackError && <Form.Text className='headerWarning'>{feedbackError}</Form.Text>}
                            </Form.Group>
                            <Button variant="primary" type="submit">
                              {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Container>
        </div>
  );
};

export default FeedbackForm;
