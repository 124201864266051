import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

import "./DescSection.css";

class DescSection extends Component {

  render() {
    return (
        <Col className="chainCol" xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="descSection">
                <div className="descSectionTitle">
                {this.props.title}
                </div>
                <p className="descSectionContent">
                {this.props.desc}
                </p>
                {this.props.link && (
                  <p className="descSectionNav">
                    <a href={this.props.link} className="descSectionContent animLinkVisual">{this.props.linkTitle}</a>
                  </p>
                )}
            </div>
        </Col>
    );
  }
 
}

export default DescSection;
