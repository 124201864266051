import React, { Component } from 'react';

import UIUtils from "../util/UIUtils";
import "../styles/header.css";
import "./PageHeader.css";

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      
        <div className="subHeader">
            <div className="subHeader__group">
              <span className="pageTitle">{this.props.title}</span>
              {this.getAdditionalLink()}
            </div>
        </div>
    );
  }

  getTitle() {
    if (this.props.logo !== null && this.props.record) {
      return <div className="pageTitle">{this.props.logo(this.props.record.logo, this.props.record)} {this.props.title} {this.props.beta && this.props.beta === true && <span className="beta">BETA</span>}</div>;
    }

    return <div className="pageTitle">{this.props.title} {this.props.beta && this.props.beta === true && <span className="beta">BETA</span>}</div>;
  }

  getAdditionalLink() {
    if (!this.props.additionalLink) {
      return "";
    }

    return <React.Fragment>
      {this.props.additionalLink()}
    </React.Fragment>
  }
}

export default PageHeader;
