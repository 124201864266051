import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import RespUtils from '../util/RespUtils';
import SPUtilities from '../util/SPUtilities';

import { faGlobe } from '@fortawesome/free-solid-svg-icons';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faFacebook, faGithub, faInstagram, faLinkedin, faMedium, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';


class ProjectUIUtils extends React.Component {
    static formatMedia(cell, row) { 
        return ProjectUIUtils.formatMediabySize(cell, row, false);
    }

    static formatMediaSmall(cell, row) {
        return ProjectUIUtils.formatMediabySize(cell, row, true);
    }
    
    static formatMediabySize(cell, row, smallInd) { 
        let website = false;
        let websiteLink = "";
        let twitter = false;
        let twitterLink = "";
        let telegram = false;
        let telegramLink = "";
        let discord = false;
        let discordLink = "";
        let medium = false;
        let mediumLink = "";
        let github = false;
        let githubLink = "";
        let whitepaper = false;
        let whitepaperLink = "";
        let youtube = false;
        let youtubeLink = "";
        let instagram = false;
        let instagramLink = "";
        let linkedin = false;
        let linkedinLink = "";
        let facebook = false;
        let facebookLink = "";
        let loop = false;
        let loopLink = "";
        
        if (row.website && row.website != "") {
            website = true;
            websiteLink = SPUtilities.getUrl(row.website);
        }
        if (row.twitter && row.twitter != "") {
            twitter = true;
            twitterLink = "https://twitter.com/" + row.twitter;
        }
        if (row.telegram && row.telegram != "") {
            telegram = true;
            telegramLink = "https://t.me/" + row.telegram;
        }
        if (row.discord && row.discord != "") {
            discord = true;
            discordLink = SPUtilities.getUrl(row.discord);
        }
        if (row.medium && row.medium != "") {
            medium = true;
            mediumLink = SPUtilities.getUrl(row.medium);
        }
        if (row.github && row.github != "") {
            github = true;
            githubLink = "https://github.com/" + row.github;
        }
        if (row.whitepaper && row.whitepaper != "") {
            whitepaper = true;
            whitepaperLink = SPUtilities.getUrl(row.whitepaper);
        }
        if (row.youtube && row.youtube != "") {
            youtube = true;
            youtubeLink = SPUtilities.getUrl(row.youtube);
        }
        if (row.instagram && row.instagram != "") {
            instagram = true;
            instagramLink = "https://www.instagram.com/" + row.instagram;
        }
        if (row.linkedin && row.linkedin != "") {
            linkedin = true;
            linkedinLink = "https://www.linkedin.com/" + row.linkedin;
        }
        if (row.facebook && row.facebook != "") {
            facebook  = true;
            facebookLink = "https://www.facebook.com/" + row.facebook;
        }
        if (row.loopMarket && row.loopMarket != "") {
            loop  = true;
            loopLink = row.loopMarket;
        }

        if (smallInd) {
            return (
                <div>
                    {website && <a href={websiteLink} target="_blank" title="website" className="mediaCustom"><FontAwesomeIcon icon={faGlobe} /></a>}
                    {twitter && <a href={twitterLink} target="_blank" title="twitter" className="mediaCustom"><FontAwesomeIcon icon={faTwitter} /></a>}
                    {telegram && <a href={telegramLink} target="_blank" title="telegram" className="mediaCustom"><FontAwesomeIcon icon={faTelegram} /></a>}
                    {discord && <a href={discordLink} target="_blank" title="discord" className="mediaCustom"><FontAwesomeIcon icon={faDiscord} /></a>}
                    {youtube && <a href={youtubeLink} target="_blank" title="youtube" className="mediaCustom"><FontAwesomeIcon icon={faYoutube} /></a>}
                    {medium && <a href={mediumLink} target="_blank" title="medium" className="mediaCustom"><FontAwesomeIcon icon={faMedium} /></a>}
                    {github && <a href={githubLink} target="_blank" title="github" className="mediaCustom"><FontAwesomeIcon icon={faGithub} /></a>}
                    {instagram && <a href={instagramLink} target="_blank" title="instagram" className="mediaCustom"><FontAwesomeIcon icon={faInstagram} /></a>}
                    {linkedin && <a href={linkedinLink} target="_blank" title="linkedin" className="mediaCustom"><FontAwesomeIcon icon={faLinkedin} /></a>}
                    {facebook && <a href={facebookLink} target="_blank" title="facebook" className="mediaCustom"><FontAwesomeIcon icon={faFacebook} /></a>}
                </div>
            );
        } else {
            return (
                <div>
                    {website && <a href={websiteLink} target="_blank" title="website" className="mediaCustom"><FontAwesomeIcon icon={faGlobe} /></a>}
                    {twitter && <a href={twitterLink} target="_blank" title="twitter" className="mediaCustom"><FontAwesomeIcon icon={faTwitter} /></a>}
                    {telegram && <a href={telegramLink} target="_blank" title="telegram" className="mediaCustom"><FontAwesomeIcon icon={faTelegram} /></a>}
                    {discord && <a href={discordLink} target="_blank" title="discord" className="mediaCustom"><FontAwesomeIcon icon={faDiscord} /></a>}
                    {youtube && <a href={youtubeLink} target="_blank" title="youtube" className="mediaCustom"><FontAwesomeIcon icon={faYoutube} /></a>}
                    {medium && <a href={mediumLink} target="_blank" title="medium" className="mediaCustom"><FontAwesomeIcon icon={faMedium} /></a>}
                    {github && <a href={githubLink} target="_blank" title="github" className="mediaCustom"><FontAwesomeIcon icon={faGithub} /></a>}
                    {instagram && <a href={instagramLink} target="_blank" title="instagram" className="mediaCustom"><FontAwesomeIcon icon={faInstagram} /></a>}
                    {linkedin && <a href={linkedinLink} target="_blank" title="linkedin" className="mediaCustom"><FontAwesomeIcon icon={faLinkedin} /></a>}
                    {facebook && <a href={facebookLink} target="_blank" title="facebook" className="mediaCustom"><FontAwesomeIcon icon={faFacebook} /></a>}
                </div>
            );
        }
    }


    static getTwitterTimeline(twitterHandle) {
        let height = "500px";
        if (RespUtils.isMobileView()) {
          height = "300px";
        }
    
        if (twitterHandle && twitterHandle != "") {
          return <TwitterTimelineEmbed sourceType="profile"
            screenName={twitterHandle} 
            options={{height: height}} />;
        }
        // options={{height: 400, width: 300}}
    
        return "";
    }
    
}

export default ProjectUIUtils;
