import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faComment } from '@fortawesome/free-solid-svg-icons';

import SSUtils from '../util/SSUtils';

import "./Footer.css";
// import FeedbackForm from '../components/FeedbackForm';

class Footer extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isFeedbackOpen: false,
  //   };
  //   this.toggleFeedbackForm = this.toggleFeedbackForm.bind(this);
  // }

  render() {
    return (
      <div>
        {SSUtils.getSupportUs()}
        <div className="compFooter">
          <div className="socialMedias">
            <a href="https://twitter.com/SmartStake" target="_blank" title="twitter" className="mediaCustom"><FontAwesomeIcon icon={faTwitter} /></a>
            &nbsp;&nbsp;&nbsp;
            <a href="https://t.me/SmartStake" target="_blank" title="telegram" className="mediaCustom"><FontAwesomeIcon icon={faTelegram} /></a>
            &nbsp;&nbsp;&nbsp;
            <a href="mailto:info@smartstake.io" title="email" className="mediaCustom"><FontAwesomeIcon icon={faEnvelope} /></a>
            &nbsp;&nbsp;&nbsp;
            <a href="/feedback" title="Feedback form" className="mediaCustom"><FontAwesomeIcon icon={faComment} /></a>
          </div>
          {/* <span className="versionInfo">{constants.APP_KEY} {global.appVersion}</span> */}
        </div>
      </div>
    );
  }

  // <FeedbackForm isOpen={this.state.isFeedbackOpen} onClose={this.toggleFeedbackForm} />
  // toggleFeedbackForm() {
  //   console.log("in toggleFeedbackForm");
  //   if (this.state.isFeedbackOpen === false) {
  //     this.setState({"isFeedbackOpen": true});
  //   } else {
  //     this.setState({"isFeedbackOpen": false});
  //   }
  // }
}

export default Footer;
