import React from "react";
import {Navbar, Nav} from 'react-bootstrap';

import Dashboards from "../base/Dashboards";
import constants from "../constants";
import './NavBar.css';
import RespUtils from "../util/RespUtils";

class NavBar extends React.Component {

  /*
        <Navbar.Brand href="/"><img src="/images/smartstake.png" id = "mainLogo"
          className="d-inline-block align-top" alt={constants.app.title + " by Smart Stake"}
          /><span className="mainTitle">{constants.app.title}{constants.ui.beta === true && (<span className="beta">Beta</span>)} {this.getSS()}</span></Navbar.Brand>
  */
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" className="customNavBar">
        <Navbar.Brand href="/"><img src="/images/smartstake.png" id = "mainLogo"
          className="d-inline-block align-top" alt={"Smart Stake" + constants.app.title}
          /><span className="mainTitle">Smart Stake</span></Navbar.Brand>
          <Nav className="customNavBar">
            {RespUtils.isNotTabletView() && <Nav.Link eventKey={1.1} className="customNavLink" href={"/"}>Home</Nav.Link>}
            <Dashboards />
            {RespUtils.isNotTabletView() && <Nav.Link eventKey={1.2} className="customNavLink" href={"/feedback"}>Contact</Nav.Link>}
          </Nav>
      </Navbar>
    );
  }

  // <DashboardsNav />
  // <Nav.Link eventKey={1.13} className="customNavLink" href="/stats">Network</Nav.Link>
  // <Nav.Link>
  //   <Form inline>
  //     <Dropdown onSelect={this.switchNetwork} values={NetworkUtils.getNetworks()}
  //       addAll={false} addBlank={false} defaultValue={defaultNetwork}/>
  //   </Form>
  // </Nav.Link>

  getSS() {
    // if(RespUtils.isMobileView()) {
    //   return <font size="2">Smart Stake</font>
    // }

    // return <small>by Smart Stake</small>
    return (<span className="ssSubtitle">by Smart Stake</span>);
  }
}

export default NavBar;
