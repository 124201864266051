import React from 'react';
import constants from '../constants';
import RespUtils from './RespUtils';

class UITableUtils extends React.Component {

  static getResponsiveWidth(thisObj) {
    let width = "90%"
    if (!thisObj.state.responsive) {
      width =  window.innerWidth * 1.5;
      // if (width < 800) {
      //   width = 1200;
      // }
    }

    return width;
  }

  static getPageOptions(thisObj) {
    const options = {
      onlyOneExpanding: true,
      sizePerPageList: [
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '200', value: 200},
        {text: '500', value: 500}
      ]
    };

    return options;
  }

  static getPageOptionsSmall(thisObj, minSize) {
    let options;
    if (minSize) {
      options = {
        onlyOneExpanding: true,
        sizePerPageList: [
          {text: minSize, value: minSize},
          {text: '25', value: 25},
          {text: '50', value: 50},
          {text: '100', value: 100},
          {text: '200', value: 200},
          {text: '500', value: 500}],
      };
    } else {
      options = {
        onlyOneExpanding: true,
        sizePerPageList: [
          {text: '25', value: 25},
          {text: '50', value: 50},
          {text: '100', value: 100},
          {text: '200', value: 200},
          {text: '500', value: 500}],
      };
    }

    return options;
  }

  static renderSwitchView(thisObj) {
    let isSmallDevice = (window.innerWidth < 1400);

    if (!isSmallDevice) {
      // console.log("large device. switch view not required");
      return;
    }

    let imageSize = 32;
    let viewLabel = "Switch to desktop view";
    let viewSrc = "/images/expand.png";

    // window.localStorage.setItem(FavUtils.getDefaultViewKey(), "Favs");
    let desktopView = window.localStorage.getItem("desktopView");
    // console.log("renderSwitchView: " + desktopView);
    // console.log((desktopView != null));
    // console.log(desktopView);
    if (desktopView != null && desktopView == 'true') {
      // console.log("render switch to default view");
      viewLabel = "Switch to default view";
      viewSrc = "/images/collapse.png";
    }

    return <span>{' '}<img src={viewSrc} onClick={UITableUtils.switchView} title={viewLabel}
      className="imgicon" width={imageSize} height={imageSize} /></span>
  }

  static isDefaultView() {
    let isSmallDevice = (window.innerWidth < 1400);

    if (!isSmallDevice) {
      return true;
    }

    let desktopView = window.localStorage.getItem("desktopView");
    if (desktopView != null && desktopView == 'true') {
      // console.log("render switch to desktop view");
      return false;
    }

    return true;
  }

  static switchView() {
    let desktopView = window.localStorage.getItem("desktopView");
    if (desktopView != null && desktopView == 'true') {
      window.localStorage.setItem("desktopView", 'false');
    } else {
      window.localStorage.setItem("desktopView", 'true');
    }

    window.location.reload();
  }

  static progressFormatter(cell, row) {
    // console.log("in progressFormatter: ", cell);
    let value = cell;
    let desc = "";

    if (cell) {
      // console.log((typeof cell), " - in percentFormatter: ", cell);
      value = (cell).toFixed(3).replace(/[.,]00$/, "");
    }

    desc = "Voting Power Percentage: " + value + "%.<br/>";
    var cumWeightFormatted = (row.cumulativeWeight).toFixed(2).replace(/[.,]00$/, "");
    desc += "Cumulative Voting Power Percentage: " + (cumWeightFormatted) + "%.<br/>";

    value = value + "%" ;
      // return <ProgressBar striped variant="warning" now={row.cumulativeWeight} label={<span className="progressStyle">{cell}</span>} />;

    // console.log("adding tip - value: ", value);
    return (<span data-for="main" data-tip={desc} data-iscapture="true">{value}</span>);
  }

  static progressStyle(cell, row) {
    let cellValueProgress = row.cumulativeWeight;
    let progress = row.cumulativeWeight - row.votingPowerPercentage;
    let bgColor = constants.colors["--color-custom-surface"];
    // console.log("bgColor: ", bgColor);
    let customColor = constants.colors["--color-text-gray"];
    let cellValueColor = constants.colors["--color-smart-primary"];
    // console.log("customColor: ", customColor);

    // let remainder = 100-progress;
    // console.log(progress, " - ", remainder);//c2f0c2
    // return {"backgroundImage": "linear-gradient(to right, #207cca 50%,#ffffff 62%,#7db9e8 62%,#7db9e8 100%)"};
    return {"background": "linear-gradient(to right, " 
        + customColor + " " + progress + "%, " 
        + cellValueColor + " " + cellValueProgress + "%, " 
        + bgColor + " " + progress + "%, " 
        + bgColor + " 100%)"};
    // return {"backgroundImage": "linear-gradient(to right, red " + progress + "%, grey " + remainder + "%, grey 100%)"};
    // return {"background": "-linear-gradient(left, red `${progress}`%, white `${progress}`%);"};
    // return {"backgroundImage": "linear-gradient(to right, " + color + ", #ffffff)"};
  }

  static isHidden(key, dataField, initConfigFn) {
    // console.log("in isHidden(key: ", key, ", dataField: ", dataField);
    let columnsConfig = window.localStorage.getItem(key);

    // console.log("columnsConfig: ", columnsConfig);
    let columnsConfigJson;

    if (!columnsConfig || columnsConfig === null) {
      // console.log("initializing config");
      columnsConfigJson = initConfigFn();
      // console.log("after init: columnsConfig: ", columnsConfig);
    } else {
      columnsConfigJson = JSON.parse(columnsConfig)
    }

    // console.log("columnsConfigJson: ", columnsConfigJson);

    // console.log(columnsConfigJson[dataField]);
    return columnsConfigJson[dataField];
  }

  static getRespKey(key) {
    if (RespUtils.isTabletView()) {
      key = key + "_MOBILE";
    }

    console.log(key);
    return key;
  }
}

export default UITableUtils;
