import { Route, Routes } from "react-router";
import React from 'react';
import Home from "./main/Home";
// import App from "./App";
import FeedbackForm from "./components/FeedbackForm";

// import UnauthRoute from "./util/UnauthRoute";


export default ({ childProps }) => (
	<Routes>
		<Route exact path='/feedback' element={<FeedbackForm />} props={childProps} />
		<Route exact path='/' element={<Home />} props={childProps} />

		{/* Finally, catch all unmatched routes */}
		<Route element={<Home />} />
	</Routes>
);
