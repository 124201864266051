import React from 'react';
import {Form} from 'react-bootstrap';
import DataUtils from '../util/DataUtils';
import RespUtils from '../util/RespUtils';

class Dropdown extends React.Component {
  render () {
    let values = this.props.values;
    let maxLength = RespUtils.isMobileView() ? 20 : 30;
    let optionItems = values.map((value) =>
        <option key={value.code} value={value.code}>{DataUtils.limitLength(value.description, maxLength)}</option>
    );

    let size = this.props.size ? this.props.size : "sm";
    
    return (
      <Form.Control as="select" size={size} defaultValue={this.props.defaultValue} onChange={this.props.onSelect} 
          className={this.props.className}>
        {this.createExtra()}
        {optionItems}
      </Form.Control>
    );
  }

  createExtra() {
    if (this.props.addBlank === true) {
      return <option value="0"></option>;
    } else if (this.props.addAll === true) {
      return <option>All</option>;
    } else if (this.props.customValue) {
      return <option value="">{this.props.customValue}</option>;
    } else if (this.props.addEmpty === true) {
      return <option value=""></option>;
    }
    return "";
  }
}

export default Dropdown;
