export default {
  APP_KEY: "Smart Stake",
  KEY: "2mwTEDr9zXJH323M",
  SS_CHAIN_LOGO_BASE_URL: "https://static.smartstake.io/chains/",
  SS_CHAIN_LOGO_BASE_DASHBOARD_URL: "https://static.smartstake.io/",
  SPECIAL_URL: "https://static.smartstake.io/special/",
  PROJECT_LOGO_BASE_URL: "https://static.smartstake.io/",
  app: {
    title: "Smart Stake - Proof of Stake Validator",
    appName: "Analytics",
    defaultProjectLogo: "/images/smartstake.png",
  },
  colors: {
    text: "white",
    chartColor1: "darkgray",
    chartColor2: "lightblue",
    chartColor3: "lightgreen",
    chartColor4: "#faa84b",
    chartColor5: "yellow",
    chartColor6: "purple",
    chartColor7: "green",
    chartColor8: "blue",
    chartColor9: "lightgray",
    chartColorBright: "red",

    "--color-text": "white",
    "--color-text-gray": "#6d738c",
    "--color-background": "#212024",
    "--color-smart-primary": "#faa84c",
    "--color-smart-primary-bright": "#ffc107",
    "--color-smart-main-surface": "#0f1011",
    "--color-smart-main-surface--accent": "#1b191b",
    "--color-custom-surface": "#17141a",
    chartColorsArray: ["#faa84b", "purple", "green", "red", "blue", "lightblue", "lightgreen", "lightgray", "yellow"],
  },
  screenTour: {
    validators: [
          {selector: '.view-tag', content: 'Filter validators by status'},
          {selector: '.tourFavorite', content: 'Select/unselect favorites (one-time setting)'},
          {selector: '.tourValName', content: 'Click validator name to open validator home page'},
          {selector: '.tourColumnSettings',content: 'Change comparison data for assessing validators (one-time setting)'},
          {selector: '.tourHelp',content: 'Show this help again. Click X or anywhere in the screen to close.'},
        ]
  },
  menuStyle: {
    borderRadius: '3px',  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 1)', padding: '2px 0',
    fontSize: '90%', position: 'fixed',
    overflow: 'auto', maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
    zIndex: '998'},
};
