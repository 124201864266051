import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import Amplify from 'aws-amplify';
// import config from './config';

// Amplify.configure({
// 	API: {
// 		endpoints: [
// 			{
// 				name: 'stakingService',
// 				endpoint: config.apiGateway.URL,
// 				region: config.apiGateway.REGION
// 			},
// 			{
// 				name: 'special',
// 				endpoint: config.apiGateway.URL_SPECIAL,
// 				region: config.apiGateway.REGION
// 			},
// 			{
// 				name: 'common',
// 				endpoint: config.apiGateway.URL_COMMON,
// 				region: config.apiGateway.REGION
// 			}
// 		]
// 	}
// });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
// ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
