import React, { Component } from "react";
// import Routes from './Routes-auth';
import {BrowserRouter as Router} from 'react-router-dom';

import "./App.css";
import DefaultRoutes from './DefaultRoutes';
import NavBar from './main/NavBar';
import Footer from './base/Footer';
import config from './config';
import constants from "./constants";
// import Home from "./main/Home";
import Contact from "./base/Contact";

class App extends Component {
  constructor(props) {
		super(props);
    this.state = {};
	}

  componentDidCatch(error, errorInfo) {
    // Catch errors in any child components and re-renders with an error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

	async componentDidMount() {
	}

  getNonProdWarning() {
    if (config.apiGateway.ENV == "prod") {
      return "";
    }

    return (<div className="headerWarning"><p/><p/>This is a test website. Contact the website operator to report the issue.</div>);
  }

  render() {
    if (this.state.error) {
      // Fallback UI if an error occurs
      console.log(this.state.error);
      console.log(this.state.errorInfo.componentStack);
      return (
       <div>
         <h3>{"Oh-no! Something went wrong"}</h3>
         <p className="red">Please <a href="javascript:location.reload(true);">reload</a> the screen and try again. </p>
         <p align="center"><a href="javascript:window.location='/'">Home</a>&nbsp;&nbsp;&nbsp;<a href="javascript:window.location.reload(true);">Reload</a></p>
       </div>
      );
    }

    return this.renderDefaultContainer();
  }

  renderDefaultContainer() {
    document.title = constants.app.title;

    const childProps = {
    };

    return (<Router><div className="App">
        <div id="outer-container" className="divWithText">
          <NavBar {...childProps} onFullScreen={this.goFull}/>
          <main id="page-wrap">
            {this.getNonProdWarning()}
            <DefaultRoutes childProps={childProps} />
            {/* <Home /> */}
          </main>
          <Footer/>
          <Contact />
        </div>
      </div></Router>);

/*    return (
      <Router>
        <div className="App">
          <div id="outer-container" className="divWithText">
            <NavBar {...childProps} onFullScreen={this.goFull}/>
            <main id="page-wrap">
              {this.getNonProdWarning()}
              <DefaultRoutes childProps={childProps} />
            </main>
            <Footer/>
          </div>
        </div>
      </Router>
    );
  */  
    // return (
    // <CacheBuster>
    //   {({ loading, isLatestVersion, refreshCacheAndReload }) => {
    //     if (loading) return null;
    //     if (!loading && !isLatestVersion) {
    //       refreshCacheAndReload();
    //     }

       
    //     return (
    //       <Router>
    //         <div className="App">
    //           <div id="outer-container" className="divWithText"><font color="red">TEST</font>
    //             <NavBar {...childProps} handleLogout={this.handleLogout} onFullScreen={this.goFull}/>
    //             <main id="page-wrap">
    //               {this.getNonProdWarning()}
    //               <DefaultRoutes childProps={childProps} />
    //             </main>
    //             <Footer/>
    //           </div>
    //         </div>
    //       </Router>
    //     );
    //   }}
    // </CacheBuster>
    // );
  }
}

export default App;
