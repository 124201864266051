export default {
  // constants: {
  //   APP: "ATOM",
  //   KEY: "2mwTEDr9zXJH323M",
  // },

  // apiGateway: {
  //   //DEV
  //   REGION: 'us-east-1',
  //   URL: 'https://ogdb577wp4.execute-api.us-east-1.amazonaws.com/dev/',
  //   GA_ID: "UA-169004578-1",
  //   ENV: "dev",
  //   NETWORK: "stakingService",
  //   CHAIN_ID: "mainnet",
  //   URL_SPECIAL: 'https://dev.smartstakeapi.com/',
  //   URL_COMMON: 'https://7nkwv3z5t1.execute-api.us-east-1.amazonaws.com/prod/',
  // },

  apiGateway: {
    //PROD
    REGION: 'us-east-1',
    URL: 'https://7nkwv3z5t1.execute-api.us-east-1.amazonaws.com/prod/',
    GA_ID: "UA-169004578-1",
    ENV: "prod",
    NETWORK: "stakingService",
    CHAIN_ID: "mainnet",
    URL_SPECIAL: 'https://prod.smartstakeapi.com/',
    URL_COMMON: 'https://7nkwv3z5t1.execute-api.us-east-1.amazonaws.com/prod/',
  },
};
