import React from "react";
import { Container, Row } from "react-bootstrap";
import PageHeader from "../components/PageHeader";

import DescSection from "./DescSection";
import "./WhyChoose.css";

class WhyChoose extends React.Component {
  render() {
    // console.log("in why choose");
    return <React.Fragment>
        <PageHeader title={"Why choose Smart Stake?"} thisObj={this} /> 
        <Container fluid>
            <Row>
                {/* Smart Stake participate in networks with the aim of strengthening the ecosystem in multiple ways. We provide staking service with realtime performance assessment tools for network participants. What sets us apart is the custom metrics that we introduce based on each individual network's unique needs. We strive to demystify blockchain data and simplify the experience of all stakeholders. */}
                <DescSection title="Feature Rich" desc="Builds custom, easy to understand, and user centric analytics dashboards for all the network participants including validators, networks, relayers, & decentralization." />
                <DescSection title="Transparent" desc="The health of validators is always visible in near-realtime on performance dashboards. Historical performance data is available via performance dashboards." />
                <DescSection title="Professional" desc="Uses hardened bare metal servers hosted in cloud, with high-end technical specifications, and use redundancy. Uses realtime monitoring and automated alerts to ensure consistently high uptime." />
                <DescSection title="Commission & Returns" desc="Uses fixed commission model and never increases commission after initial promotion ends. Auto compounding is supported via Restake for supported networks for maximum returns." />
                <DescSection title="Relayer" desc="Running relayers in 25+ networks. IBC relayers play an important role in interchain transactions."
                  link="https://relayers.smartstake.io/relayer/DD06F013A474ACA3" linkTitle="Check Relayer Contributions/Performance"/>
                <DescSection title="Governance" desc="Actively participates in governance in all networks. Governance features are provided in most dashboards for analytics." />
            </Row>
        </Container>
    </React.Fragment>;
  }

}

export default WhyChoose;